<template>
  <div
    class="pop-feedback"
    v-show="popCourseId>0 && aid==sid_"
    @click="$emit('clickfeedback',0)"
  >
    <div class="close-btn" @click.stop="closeFeedback">
      <img src="@/assets/img/icon-close.png" alt="" class="close" />
    </div>
    <img :src="popImgUrl" alt="" class="pop-bg" />
    <div class="pop-message">{{ closePop }}秒后自动关闭</div>
  </div>
</template>

<script>
import { sid, getSue, feedbackPopId } from "@/utils/api.js";
export default {
  props: {
    popCourseId: [String, Number],
    groupId: [String, Number],
  },
  data() {
    let _this = this;
    return {
      tip1: 0,
      tip2: 0,
      wsServer: "",
      closePop: 20,
      popImgUrl: "",
      countDownId: 0,
      timer: null,
      timer_s: null,
      times: 0,
      aid: "",
      sid_: sid(_this.global.year).toString(),
    };
  },
  created(){
  // mounted() {
    // console.log(this.popCourseId);
    // if(this.popCourseId > 0){
    this.getSponsor();
    // this.createWebsocket(); // websocket
    // this.createWebsocket_(); // websocket-心跳检测
    // }
  },
  mounted() {
    this.createWebsocket_(); // websocket心跳检测
    // this.createRollPolling();  // 轮询
    // this.global.year=="2022sz" ? this.createWebsocket_() : this.createRollPolling();
  },
  watch: {
    $route(to, from) {
      // console.log(to, from);
      // console.log(to.path.split('/')[1]);
      if(to.path.split('/')[1] != from.path.split('/')[1]) {
        this.createWebsocket_(); // websocket心跳检测
        // this.createRollPolling();  // 轮询
        // this.global.year=="2022sz" ? this.createWebsocket_() : this.createRollPolling();
      }
    },
  },
  methods: {
    getSponsor() {
      this.$http
        .jsonp(getSue + feedbackPopId(this.global.year))
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log(res);
          if (res.errno == 0) {
            this.popImgUrl =
              res.data[0] && res.data[0][0] && res.data[0][0].file;
          }
        });
    },
    // websocket
    createWebsocket() {
      // this.wsServer = new WebSocket("wss://acrm.msup.com.cn:9503");
      // var ws = this.wsServer;
      // var wsServer = "wss://47.104.78.204:9503";
      var wsServer = "wss://acrm.msup.com.cn:9503";
      var ws = new WebSocket(wsServer);
      ws.onopen = (evt) => {
        console.log("WebSocket 连接成功 ", this.groupId);
        ws.send(this.groupId);
        // var res = ws.send(this.groupId);
        // console.log(res);
      };
      //消息通知
      ws.onmessage = (evt) => {
        console.log("WebSocket onmessage：", evt);
        if(evt.data[0]=='{'){
          // const result = evt.data[0]=='{' ? JSON.parse(evt.data) : {time:1};
          const result = JSON.parse(evt.data);
          this.aid = result["sid"];
          // console.log(result);
          // 弹出反馈提示弹窗
          this.$emit("showpop", evt.data);
          // this.$emit("showpop", result.time * 1000);
          // this.popCourseId = this.getCourseId(result.time * 1000);
          this.popCountDown();
        }
      };
      ws.onclose = function (evt) {
        console.log("WebSocket onclose：", evt);
        // ws.send(this.groupId);
        let _this = this;
        let check = false;
        let _t = setInterval(()=>{
          if(check){
            _t && clearInterval(_t);
          } else {
            ws = new WebSocket(wsServer);
            ws.onopen = (evt) => {
              console.log("WebSocket 连接成功 ", _this.groupId);
              ws.send(_this.groupId);
              check = true;
              // var res = ws.send(this.groupId);
              // console.log(res);
            };
            //消息通知
            ws.onmessage = (evt) => {
              console.log("WebSocket onmessage：", evt);
              if(evt.data[0]=='{'){
                // const result = evt.data[0]=='{' ? JSON.parse(evt.data) : {time:1};
                const result = JSON.parse(evt.data);
                // console.log(result);
                // 弹出反馈提示弹窗
                _this.$emit("showpop", evt.data);
                // this.$emit("showpop", result.time * 1000);
                // this.popCourseId = this.getCourseId(result.time * 1000);
                _this.popCountDown();
              }
            };
          }
        }, 10000);
      };
      ws.onerror = function (evt, e) {
        console.log("WebSocket 连接失败：" + evt.data);
        ws.send(this.groupId);
      };
    },
    createWebsocket_() {
      // 使用webSocket心跳重连
      let timeInit = new Date().getTime();
      var wsServer = "wss://acrm.msup.com.cn:9503";
      var ws, tt;
      var lockReconnect = false; // 避免重复连接
      var _this = this;
      // 请求连接(重连)
      var reconnect = (url)=>{
        if(lockReconnect) {
          return;
        };
        lockReconnect = true;
        // 没连接上会一直重连，设置延迟避免请求过多
        tt && clearTimeout(tt);
        tt = setTimeout(function () {
          _createWebSocket(url);
          lockReconnect = false;
        }, 4000);
      }
      // 心跳检测(webSocket替代轮询实现即时通讯，通过心跳重连避免弱网或短暂断网等状况下服务器端未触发onclose客户端也无法得知是否断开的情况)
      var heartCheck = {
        timeout: 30000,
        timeoutObj: null,
        serverTimeoutObj: null,
        start: function(from,data){ // 控制此处this指向上级(heartCheck)
          // console.log('WebSocket 检测 start', this.timeout/1000, from, new Date().getTime()-timeInit);
          // 一些操作：
          if(from == 'open'){
            console.log("WebSocket 检测 start：from open >>>>>>", this.timeout/1000, new Date().getTime()-timeInit);
            ws.send(_this.groupId);
          }
          if(from == 'msg'){
            console.log("WebSocket 检测 start：from msg >>>>>>", this.timeout/1000, new Date().getTime()-timeInit);
            if(data[0]=='{'){
              // const result = data[0]=='{' ? JSON.parse(data) : {time:1};
              // const result = JSON.parse(data);
              // console.log(result);
              // 弹出反馈提示弹窗
              // _this.$emit("showpop", result.time * 1000);
              // 取消时间限制
              _this.aid = JSON.parse(data)["sid"];
              _this.$emit("showpop", data);
              // _this.popCourseId = _this.getCourseId(result.time * 1000);
              _this.popCountDown();
            }
          }
          // 检测：
          this.timeoutObj && clearTimeout(this.timeoutObj);
          this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
          this.timeoutObj = setTimeout(() => { //这里发送一个心跳，后端收到后，返回一个心跳消息，
            console.log('WebSocket 检测 send');
            ws.send(_this.groupId);
            // this.serverTimeoutObj = setTimeout(function() {
            //   console.log(">>>>>> close > open");
            //   ws.close();
            //   _createWebSocket();
            // }, this.timeout);
          }, this.timeout)
        }
      }
      // 封装监听事件(初始化)，连接成功或接收消息时重置心跳检测，连接失败或错误时重连
      var init = (wsUrl) => {
        ws.onclose = function (evt) {
          console.log("WebSocket 已关闭：", evt);
          reconnect(wsUrl);
        };
        ws.onerror = function (evt, e) {
          console.log("WebSocket 连接失败：" + evt.data);
          reconnect(wsUrl);
        };
        ws.onopen = (evt) => {
          console.log("WebSocket 连接成功 ", this.groupId);
          heartCheck.start('open'); //心跳检测重置
        };
        ws.onmessage = (evt) => {
          console.log('WebSocket 接收到消息 ', evt);
          heartCheck.start('msg', evt.data);
        }
        window.onbeforeunload = ()=>{ // 即将离开当前页面(关闭或刷新)时关闭ws
          ws.close();
        }
      }
      // 调用(初始化)
      var _createWebSocket = (wsUrl) => {
        try {
          ws = new WebSocket(wsUrl);
          init(wsUrl);
        } catch(e) {
          console.log('catch');
          reconnect(wsUrl);
        }
      }
      _createWebSocket(wsServer);
    },
    // 反馈赢大奖的倒计时
    popCountDown() {
      // this.closeFeedback();
      this.closePop = 20;
      this.countDownId && clearInterval(this.countDownId);
      this.countDownId = 0;
      this.countDownId = setInterval(() => {
        // console.log(this.closePop);
        if (this.closePop > 1) {
          this.closePop -= 1;
          this.$forceUpdate();
        } else {
          this.closeFeedback();
        }
      }, 1000);
    },
    // 清除定时器
    closeFeedback() {
      this.$emit("showpop", 0);
      this.countDownId && clearInterval(this.countDownId);
      this.countDownId = 0;
    },
    // 轮询
    createRollPolling() {
      // console.log(sid(this.global.year), this.groupId);
      // &courseId=${}
      let _sid = sid(this.global.year);
      let _gid = this.groupId;
      this.$http
        .jsonp(`https://www.msup.com.cn/api/api-common/get-feedback?schedulId=${_sid}&groupId=${_gid}`)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          console.log("》》》", this.times++, this.sid_, res);
          if(res.status === "200"){
            let time = localStorage.getItem("rollPollingTime");
            if(res.data.startTime != time){
              // this.rollPollingTime = res.data.startTime;
              localStorage.setItem("rollPollingTime", res.data.startTime);
              this.aid = res.data.schedulId;
              this.$emit("showpop", res.data);
              this.popCountDown();
            }
          }
          let num = 0;
          this.timer && clearTimeout(this.timer);
          this.timer_s && clearInterval(this.timer_s);
          num = 0;
          this.timer_s = setInterval(() => {
            console.log(num++);
          }, 1000)
          this.timer = setTimeout(() => {
            this.createRollPolling();
          }, 30000);
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.pop-feedback {
  background-color: #fff;
  color: red;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 30px 25px 30px;
  // width: 200px;
  // height: 150px;
  z-index: 3;
  cursor: pointer;
  .close-btn {
    position: absolute;
    // right: 4%;
    // top: 4%;
    right: 4px;
    top: 4px;
    z-index: 3;
    .close {
      width: 24px;
      height: 24px;
    }
  }
  .pop-bg {
    // position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    // border: 1px solid #eee;
    max-width: 100%;
  }
  .pop-message {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
    font-size: 12px;
    color: #333;
    width: 100%;
    text-align: center;
  }
}
</style>