<template>
  <div class="live-content">
    <div class="pc">
      <div class="live-con">
        <!-- 反馈 -->
        <PopFeedback
          :popCourseId="popCourseId"
          :groupId="groupId"
          @clickfeedback="toClickfeedback"
          @showpop="getCourseId"
        ></PopFeedback>
        <!-- 回放列表 -->
        <div 
          class="playbacklist" 
          v-if="isplayback" 
          :style="playbackListShow?'right: 0;':'right: -278px;'"
        >
          <div class="le">
            <div :class="playbackListShow?'_open':'_close'" @click="playbackListShow=!playbackListShow" :title="`点击${playbackListShow?'关闭':'展开'}回放列表`">
              <div :style="playbackListShow?'transform: rotateY(180deg);':'transform: rotateY(0deg);'">
                <svg t="1659428319809" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4618" width="22" height="58"><path d="M170.666667 533.333333L490.666667 213.333333l29.866666 29.866667-290.133333 290.133333 290.133333 290.133334-29.866666 29.866666L170.666667 533.333333z m298.666666 0L789.333333 213.333333l29.866667 29.866667-290.133333 290.133333 290.133333 290.133334-29.866667 29.866666-320-320z" fill="#ffffff" p-id="4619"></path></svg>
              </div>
            </div>
          </div>
          <div class="ri" :style="livetype==1 ? 'background-color:#101010;' : ''">
            <div>
              <p v-for="(item, index) in playbackList" :key="'playbackList'+index" :data-index="index" :class="playback==index ? 'act' : ''">
                {{item.title}}
              </p>
            </div>
          </div>
        </div>
        <!-- 播放器 -->
        <div
          v-if="livetype == 0"
          id="player"
          class="live-player"
          :class="{ 'small-view': showSmallView, 'small-top': shrinkSmallView }"
        ></div>
        <div v-else-if="livetype == 1" class="live-player haschatroom" :class="{ 'small-view': showSmallView, 'small-top': shrinkSmallView }">
          <div id="player"></div>
          <div id="chatroom" class="live-chatroom" v-show="!showSmallView"></div>
        </div>
      </div>
      <!-- 信息 -->
      <div class="intro-wrapper">
        <div class="left">
          <h4 class="title">
            {{ titleList[global.year][groupId] }}
          </h4>
          <p class="text" v-if="liveDetail.lecturers">
            <!-- 专题：{{ finishing.venueName[groupId] }} &nbsp; &nbsp;
            &nbsp;案例来源：{{
              liveDetail.lecturers[0] && liveDetail.lecturers[0].company
            }} -->
          </p>
          <!-- <div class="line-wrapper">
            <div
              class="line-item"
              :class="addLineClass(1)"
              @click="toggleLine(1)"
            >
              线路一
            </div>
            <div
              class="line-item"
              :class="addLineClass(2)"
              @click="toggleLine(2)"
            >
              线路二
            </div>
          </div> -->
        </div>
        <div class="right">
          <div class="logo-wrapper">
            <img
              src="https://f.msup.com.cn/msup_offcial.jpeg"
              alt=""
              class="logo"
            />
          </div>
          <div class="offcial-account">
            <p class="name">msup</p>
            <p class="offcial-intro">
              专注于软件研发中心的快速成长，服务于软件开发团队的技能提升、软件工程的实际应用和软件品质的创新与超越。强调人员、技术、流程和管理的有机结合，注重个体的技能提升与职业发展，研发团队的管理与协作。分享世界级软件研发团队最佳管理实践!
            </p>
          </div>
          <div class="follow-btn">
            <button @click="toggleQrcode">+ 关注</button>
            <div class="qrcode-wrapper" v-show="qrcodeShow">
              <div class="wechat-wrapper">
                <img
                  src="https://f.msup.com.cn/live-wechat-logo.png"
                  alt=""
                  class="wechat-logo"
                />
                微信扫码
              </div>
              <img
                src="https://f.msup.com.cn/msup_offcial_qrcode.jpg"
                alt=""
                class="code"
              />
              <div class="text">扫码关注公众号</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mob">
      <div class="back-wrapper" @click="backLast">
        <img src="https://f.msup.com.cn/icon-left-white.png" class="back-btn" />
      </div>
      <div id="playerMob"></div>
      <!-- <p style="color:#000000;">{{global.year}} {{appId[0]}} {{playbackIndex}}</p> -->
      <div class="title-wrapper">
        <!-- <div class="line-wrapper">
          <div
            class="line-item"
            :class="addLineClass(1)"
            @click="toggleLine(1)"
          >
            线路一
          </div>
          <div
            class="line-item"
            :class="addLineClass(2)"
            @click="toggleLine(2)"
          >
            线路二
          </div>
        </div> -->
        <h4 class="title">
          <!-- {{ liveDetail.courseTitle }} -->
          {{ titleList[global.year][groupId] }}
        </h4>
        <p class="text" v-if="liveDetail.lecturers">
          <!-- 专题：{{ finishing.venueName[groupId] }} &nbsp; &nbsp;
          &nbsp;案例来源：{{
            liveDetail.lecturers[0] && liveDetail.lecturers[0].company
          }} -->
        </p>
      </div>
      <div class="offcial-wrapper">
        <div class="logo-wrapper">
          <img
            src="https://f.msup.com.cn/msup_offcial.jpeg"
            alt=""
            class="logo"
          />
        </div>
        <div class="offcial-account">
          <p class="name">msup</p>
          <p class="offcial-intro">
            专注于软件研发中心的快速成长，服务于软件开发团队的技能提升、软件工程的实际应用和软件品质的创新与超越。强调人员、技术、流程和管理的有机结合，注重个体的技能提升与职业发展，研发团队的管理与协作。分享世界级软件研发团队最佳管理实践!
          </p>
        </div>
        <div class="follow-btn">
          <button @click="showMessage">关注</button>
          <div class="qrcode-wrapper" v-show="qrcodeShow">
            <div class="wechat-wrapper">
              <img
                src="https://f.msup.com.cn/live-wechat-logo.png"
                alt=""
                class="wechat-logo"
              />
              微信扫码
            </div>
            <img
              src="https://f.msup.com.cn/msup_offcial_qrcode.jpg"
              alt=""
              class="code"
            />
            <div class="text">扫码关注公众号</div>
          </div>
        </div>
      </div>
      <div class="chatroom-con-open" @click="openChatroom()" v-if="livetype == 1">
        <p>聊天室</p>
        <p>
          <span>
            <svg t="1659428319809" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4618" width="20" height="50"><path d="M170.666667 533.333333L490.666667 213.333333l29.866666 29.866667-290.133333 290.133333 290.133333 290.133334-29.866666 29.866666L170.666667 533.333333z m298.666666 0L789.333333 213.333333l29.866667 29.866667-290.133333 290.133333 290.133333 290.133334-29.866667 29.866666-320-320z" fill="#FFFFFF" p-id="4619"></path></svg>
          </span>
        </p>
      </div>
      <div class="chatroom-con-mob" v-if="livetype == 1">
        <span class="chatroom-con-close" @click="closeChatroom()"><i>×</i></span>
        <div class="chatroom-mob">
          <div id="chatroom-mob"></div>
        </div>
      </div>
    </div>
    <!-- 图片 -->
    <div class="img-preview" v-if="showPreview">
      <i @click="closeImg()">×</i>
      <div class="img-con" @click="closeImg()">
        <img :src="imgPreview" alt="" @click="$event.stopPropagation()">
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue'
import { id_arr, livevod, livetype } from "@/utils/api.js";
import PopFeedback from "@/components/live/PopFeedback.vue";

// 登录后有门票可观看直播，没有门票提示：您当前没有观看直播的权限（开幕式登录就可以观看）
// 每次进入直播间调用接口进行记录
const channelIdList = {
  '2022sh': {
    1: "3270621",
    2: "3270627",
    3: "3270624",
    4: "3270626",
    5: "3270628",
    6: "3270629", },
    // 0: "3297991", //  测试直播间
    // 1: "3270621",
    // 2: "3270624",
    // 3: "3270626",
    // 4: "3270627",
    // 5: "3270628",
    // 6: "3270629",
    // 7: "3133030", },
  '2022sz': {
    1: "3242374",
    2: "3242556",
    3: "3242566",
    4: "3242571",
    5: "3242575",
    6: "3242577",
  },
};
const coverList = {
  1: "https://f.msup.com.cn/top100_live_cover7.png",
  2: "https://f.msup.com.cn/top100_live_cover8.png",
  3: "https://f.msup.com.cn/top100_live_cover9.png",
  4: "https://f.msup.com.cn/top100_live_cover10.png",
  5: "https://f.msup.com.cn/top100_live_cover11.png",
  6: "https://f.msup.com.cn/top100_live_cover12.png",
};

export default {
  props: {
    finishing: Object,
    selectedTime: String,
    token: String,
    detail: Object,
    playbackIndex: Number,
  },
  components: {
    PopFeedback,
  },
  data() {
    let _this = this;
    return {
      livetype: livetype(_this.global.year),
      showSmallView: false, // 控制pc播放器是否小窗显示
      playerTitle: "",
      qrcodeShow: false,
      chatroom: null,
      liveSdk: null,
      isplayback: false,
      playback: 0,
      playbackList: [], // 回放模式下回放列表
      playbackListShow: false, // 回放模式下列表是否展开
      // appSecret: "",
      appId: "",
      channelId: "",
      groupId: "",
      liveDetail: {},
      phone: "",
      name: "",
      titleList: {
        '2022sh': {
          1: "测试前沿",
          2: "大数据框架",
          3: "技术管理",
          4: "安全与风控",
          5: "开源生态",
          6: "前端新趋势",
          7: "开幕式" 
        },
        '2022sz': {
          1: "元宇宙 / Web3 / 数据安全 / IoT与边缘计算",
          2: "技术管理 / 研发效能 / 测试前沿技术 / DevOps",
          3: "音视频 / 高并发&高可用 / 基础架构 / 架构演进",
          4: "AI基础设施 / 互联网AI前沿 / 数据智能平台演进 / 湖仓一体",
          5: "移动端 / 低代码 / 大前端 / 大前端",
          6: "云上运维 / 数据库 / CloudNative(云原生) / CloudNative(云原生)",
        },
      },
      popCourseId: 0,
      showPreview: false,
      imgPreview: "",
    };
  },
  created() {
    this.appId = this.global.year=="2022sz" ? "gbr02ktml4" : "fkeg9wsa92";
    this.groupId = this.$route.query.groupid;
  },
  mounted() {
    // this.appSecret = this.global.year=="2022sz" ? "aae366369af74a71a9c83a0409e727ea" : "d519fb99440f4e1191a10d5169bc574f";
    // this.appId = this.global.year=="2022sz" ? "gbr02ktml4" : "fkeg9wsa92";
    // this.groupId = this.$route.query.groupid;
    this.channelId = channelIdList[this.global.year][this.$route.query.groupid];
    this.getUserInfo();
    this.toggleSmallView();
  },
  watch: {
    "playbackIndex"(_new, _old) {
      // console.log(_old, _new);
      if((this.detectDeviceType()==="Mobile") && (_old!=_new)){
        this.playback = parseInt(_new);
        $("#playerMob").children().remove();
        this.getUserInfo();
      }
    }
  },
  computed: {
    shrinkSmallView() {
      const height = document.documentElement.clientHeight;
      if (height <= 664) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    toClickfeedback(popCourseId) {
      // console.log(popCourseId, this.popCourseId);
      this.$emit('clickfeedbackL', this.popCourseId);
      let pop = this.popCourseId;
      this.$parent.showFeedback(pop); // 直接调用父组件方法
    },
    getCourseId(_currentTime) {
      // console.log("@showpop='getCourseId' -time：", _currentTime);
      // let currentTime = _currentTime?_currentTime:0;
      if (_currentTime === 0) {
        this.popCourseId = 0;
        return;
      }
      
      if(this.global.year.indexOf("2022") > -1){
        // webSocket取消时间限制
        let currentTime = JSON.parse(_currentTime);
        // console.log(currentTime['course_id']);
        this.popCourseId = currentTime['course_id'];
        return;
      } else {
        // 使用轮询
        this.popCourseId = _currentTime.courseId;
        return;
      }


      // 使用本地时间
      const time = new Date().getTime() - this.selectedTime * 1000;
      // 使用服务器时间
      // const time = currentTime - this.selectedTime * 1000;
      // 使用lodash获取当前日期的直播时间列表
      if (time > 24 * 60 * 60 * 1000) {
        console.log("当前日期与会议日期不符合");
        return;
      }
      const timeList = _.keys(_.get(this.detail, [this.selectedTime, "all"]));
      let myTime;
      timeList.map((item) => {
        const t =
          item.split(":")[0] * 60 * 60 * 1000 + item.split(":")[1] * 60 * 1000;
        if (time - t > 0) {
          console.log("当前时间在大会之后");
          myTime = item;
        } else {
          console.log("当前时间在大会之前");
        }
        return;
      });
      // 使用lodash获取当前时间对应的课程ID
      const timeCourseList = _.get(this.detail, [
        this.selectedTime,
        "all",
        myTime,
      ]);

      // console.log(timeCourseList);
      if (timeCourseList) {
        this.popCourseId = timeCourseList[this.groupId].courseId;
      } else {
        this.popCourseId = 0;
        console.log("不在大会时间内，获取不到courseId");
      }
    },
    // 监听滚动条，判断是否小窗口播放
    toggleSmallView() {
      const that = this;
      window.onscroll = function () {
        const smallPlayerHeight = 678;
        if (document.documentElement.scrollTop > smallPlayerHeight) {
          that.showSmallView = true;
        } else {
          that.showSmallView = false;
        }
      };
    },
    getUserInfo() {
      const token = window.localStorage.getItem("token");
      const url =
        "https://www.msup.com.cn/api/top-api/user-member?token=" + token;
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log(res);
          if (res.status === "200") {
            if (
              res.data.phone !== null &&
              res.data.phone &&
              res.data.phone !== ""
            ) {
              this.phone = res.data.phone;
            } else {
              this.phone = Math.floor(Math.random() * 100000);
            }

            this.name = `感谢${res.data.username}参加 GIAC`;
            this.getSign();
          } else if (res.status === "1011") {
            console.log("用户未登录");
          } else {
            console.log("获取用户信息失败");
          }
        });
    },
    addLineClass(line) {
      if (
        line === 1 &&
        window.location.origin === "https://www.top100summit.com"
      ) {
        return "selected";
      } else if (
        line === 2 &&
        window.location.origin === "https://top100.msup.cn"
      ) {
        return "selected";
      }
    },
    toggleLine(line) {
      const currentLine = window.location.href;
      const token = window.localStorage.getItem("token");
      if (line === 1 && currentLine !== "https://www.top100summit.com") {
        window.open(
          `https://www.top100summit.com/live?groupid=${this.$route.query.groupid}&time=${this.$route.query.time}&token=${token}`
        );
      } else if (line === 2 && currentLine !== "https://top100.msup.cn") {
        window.open(
          `https://top100.msup.cn/live?groupid=${this.$route.query.groupid}&time=${this.$route.query.time}&token=${token}`
        );
      }
    },
    backLast() {
      this.$router.go(-1);
    },
    showMessage() {
      this.$toast('请打开微信搜索"Msup"，关注公众号', 2000);
    },
    toggleQrcode() {
      this.qrcodeShow = !this.qrcodeShow;
    },
    detectDeviceType() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
        ? "Mobile"
        : "Desktop";
    },
    createConfig(timestamp) {
      let config, chatroomConfig;
      if (this.detectDeviceType() === "Mobile") {
        config = {
          pptEl: "#pptMob",
          el: "#playerMob",
          // type: "auto",
          width: "100vw",
          height: "260px",
          pptWidth: "200px",
          pptHeight: "405px",
          controllerPosition: "ppt",
          pptNavBottom: "80px",
          autoplay: true,
          // coverImage: coverList[this.groupId],
          // warmUpImg: coverList[this.groupId],
        };
        chatroomConfig = this.livetype==1 ? {
          container: "#chatroom-mob",
          width: "100%",
          height: "100%",
          showUserList: true,
          tabData: [{ name: "聊天室", type: "chat" }, { name: "学员", type: "user-list" }]
          // tabData: [{ name: "课程介绍", type: "desc" }, { name: "聊天室", type: "chat" }, { name: "学员", type: "user-list" }, { name: "反馈", type: "feedback" }]
        } : "";
      } else if (this.detectDeviceType() === "Desktop") {
        config = {
          pptEl: "#ppt",
          el: "#player",
          // type: "auto",
          // width: "1200px",
          // height: "678px",
          // width: this.livetype==1 ? "895px" : "1200px",
          // height: "678px",
          // width: "100%",
          pptWidth: "340px",
          pptHeight: "255px",
          controllerPosition: "ppt",
          pptNavBottom: "80px",
          autoplay: true,
          // warmUpImg: coverList[this.groupId], // 封面图片，移动端不生效
        };
        chatroomConfig = this.livetype==1 ? {
          container: "#chatroom",
          width: 305,
          height: "100%",
          showUserList: true,
          tabData: [{ name: "聊天室", type: "chat" }, { name: "学员", type: "user-list" }]
        } : "";
      }
      // this.createPlayer(config, timestamp);
      chatroomConfig=="" ? this.createPlayer(config, timestamp) : this.createCPlayer(config, timestamp, chatroomConfig);
    },
    async createPlayer(config, timestamp) {
      // 注意事项：appSecret，appId不建议在前端显示，不安全，请您一定要请求后端接口获取sign
      //参数结束
      //第二步：初始化直播sdk对象
      this.liveSdk = new PolyvLiveSdk({
        channelId: this.channelId,
        sign: this.sign, // 频道验证签名
        timestamp: timestamp, // 毫秒级时间戳
        appId: this.appId, // polyv 后台的appId
        // socket: this.chatroom.chat.socket,
        user: {
          userId: this.phone,
          userName: this.name,
        },
      });

      let _config = {};
      // 回放列表 livevod(this.global.year)==1为回放
      let playbackListFrom = this.global.year=='2022sh' ? 'vod' : 'playback';
      this.playbackList = await this.liveSdk.getPlaybackLists(1,5,playbackListFrom).then((res)=>{
        return res.contents;
      })
      // console.log("this.playbackList: ", this.playbackList);
      if(id_arr[this.global.year].livevod==1 && this.playbackList.length==0){
        this.$toast("当前直播暂无回放", 1000)
      }
      if(livevod(this.global.year)==1 && this.playbackList.length>1){
        this.isplayback = (this.global.year=='2022sh' && new Date().getTime()>this.selectedTime*1000) ? true : false;
        this.$emit("initLiveVod", this.isplayback?1:0);
        this.$emit("initPlaybackList", JSON.stringify(this.playbackList));
        let {videoId: fileId, url, channelSessionId: sessionId} = this.playbackList[this.playback];
        _config = { 
          ...config, 
          type: 'vod', // 'vod'设置回放模式
          fileId,
          url,
          sessionId
        };
        // console.log(livevod(this.global.year), this.playbackList, fileId, url, sessionId);
      } else {
        _config = { 
          ...config, 
          type: 'auto',
        };
      }

      // 监听频道信息并初始化播放器
      this.liveSdk.on(PolyvLiveSdk.EVENTS.CHANNEL_DATA_INIT, (event, data) => {
        //第三步：设置直播播放器
        // console.log("》》》 ",data);
        this.liveSdk.setupPlayer({
          ..._config,
        });

        // 自制列表 绑定点击事件
        if(livevod(this.global.year) == 1){
          // console.log($(".live-content .pc .playbacklist .ri p"));
          $(".live-content .pc .playbacklist .ri p").on("click", (e)=>{
            // console.log(e.target.dataset.index);
            if(this.playback == parseInt(e.target.dataset.index)){
              this.$toast("正在播放中", 600);
            } else {
              this.playback = parseInt(e.target.dataset.index);
              let {videoId: fileId, url, channelSessionId: sessionId} = this.playbackList[this.playback];
              this.liveSdk.switchVod({
                fileId,
                sessionId,
                url
              })
              // this.playbackListShow = false;
              console.log(`切换回放《${this.playbackList[parseInt(e.target.dataset.index)].title}》`);
            }
          })
        }
      });
      this.liveSdk.on(PolyvLiveSdk.EVENTS.STREAM_UPDATE, (event, status) => {
        // console.log(status);
        if (status === "live") {
          if (window.confirm("直播已开始，是否观看直播")) {
            window.location.reload();
          } else {
          }
        }
      });
    },
    // 直播-含聊天室
    createCPlayer(config, timestamp, chatroomConfig) {
      // 注意事项：appSecret，appId不建议在前端显示，不安全，请您一定要请求后端接口获取sign
      let params_c = {
        channelId: this.channelId,
        userId: this.phone,
        role: "viewer"
      };
      (async (params_c)=>{
        let params = {
          channelId: this.channelId,
          timestamp: timestamp,
          appId: this.appid
        };
        let chatData = await this.getChatroomToken(params_c).then((data=>{
          if(data.status == "200"){
            return data.data;
          } else {}
        }));
        // console.log(chatData);
        // 初始化聊天室
        this.chatroom = await new PolyvChatRoom({
          ...chatroomConfig,
          roomId: this.channelId,
          userId: this.phone,
          // nick: "游客",
          nick: this.name,
          pic: "http://livestatic.videocc.net/assets/wimages/missing_face.png",
          token: chatData.token,
          mediaChannelKey: chatData.mediaChannelKey,
          version: "2.0",
          userType: "slice",
          enableFlower: true,
          enableLike: true,
          enableBulletin: true,
          enableAsk: false,
          roomMessage: (data)=>{
            // data为聊天室socket消息，当有聊天室消息时会触发此方法
            console.log("roomMessage ",data);
            // 添加图片点击预览：
            this.$forceUpdate();
            // console.log($(".polyv-msg-main .polyv-msg-content .polyv-msg-text img.polyv-img-chat").length);
            if(data.EVENT == "CHAT_IMG"){
              setTimeout(()=>{
                $(".polyv-msg-main .polyv-msg-content .polyv-msg-text img.polyv-img-chat").unbind("click");
                $(".polyv-msg-main .polyv-msg-content .polyv-msg-text img.polyv-img-chat").on("click",(el)=>{
                  this.imgPreview = $(el.target).attr('src');
                  this.showPreview = true;
                  this.$forceUpdate();
                })
              },200);
            }
            $(".polyv-msg-main .polyv-msg-content .polyv-msg-text img.polyv-img-chat").unbind("click");
            $(".polyv-msg-main .polyv-msg-content .polyv-msg-text img.polyv-img-chat").on("click",(el)=>{
              this.imgPreview = $(el.target).attr('src');
              this.showPreview = true;
              this.$forceUpdate();
            })
            $(".live-detail .banner .shower .ri #live-chatroom .tab-chat-content").unbind("scroll");
            $(".live-detail .banner .shower .ri #live-chatroom .tab-chat-content").on('scroll',(el)=>{
              // console.log($(".live-detail .banner .shower .ri #live-chatroom .tab-chat-content")[0].scrollTop);
              // if($(".live-detail .banner .shower .ri #live-chatroom .tab-chat-content")[0].scrollTop == 0){
              //   console.log("___________ ",$(".live-detail .banner .shower .ri #live-chatroom .polyv-chat-room .polyv-chat-list>li").length);
              //   console.log("___________ ",$(".polyv-msg-main .polyv-msg-content .polyv-msg-text img.polyv-img-chat").length)
              // }
              $(".polyv-msg-main .polyv-msg-content .polyv-msg-text img.polyv-img-chat").unbind("click");
              $(".polyv-msg-main .polyv-msg-content .polyv-msg-text img.polyv-img-chat").on("click",(el)=>{
                this.imgPreview = $(el.target).attr('src');
                this.showPreview = true;
                this.$forceUpdate();
              })
            });
          },
        });
        // 初始化直播sdk对象
        this.liveSdk = await new PolyvLiveSdk({
          channelId: this.channelId,
          sign: this.sign, // 频道验证签名
          timestamp: timestamp, // 毫秒级时间戳
          appId: this.appId, // polyv 后台的appId
          socket: this.chatroom.chat.socket,
          user: {
            userId: this.phone,
            userName: this.name,
            pic: ""
          },
        });

        let _config = {};
        // 回放列表 livevod(this.global.year)==1为回放
        let playbackListFrom = this.global.year=='2022sh' ? 'vod' : 'playback';
        this.playbackList = await this.liveSdk.getPlaybackLists(1,5,playbackListFrom).then((res)=>{
          return res.contents;
        })
        console.log("this.playbackList: ", this.playbackList);
        if(livevod(this.global.year) && livevod(this.global.year)==1 && this.playbackList.length==0){
          this.$toast("当前直播暂无回放", 1000)
        }
        if(livevod(this.global.year) && livevod(this.global.year)==1 && this.playbackList.length>0){
          this.isplayback = (this.global.year=='2022sh' && new Date().getTime()>this.selectedTime*1000) ? true : false;
          this.$emit("initLiveVod", this.isplayback?1:0);
          this.$emit("initPlaybackList", JSON.stringify(this.playbackList));
          let {videoId: fileId, url, channelSessionId: sessionId} = this.playbackList[this.playback];
          _config = { 
            ...config, 
            type: 'vod', // 'vod'设置回放模式
            fileId,
            url,
            sessionId
          };
          // console.log(livevod(this.global.year), this.playbackList, fileId, url, sessionId);
        } else {
          _config = { 
            ...config, 
            type: 'auto',
          };
        }

        // 监听频道信息并初始化播放器
        this.liveSdk.on(PolyvLiveSdk.EVENTS.CHANNEL_DATA_INIT, (event, data) => {
          // console.log("》》》 ",data);
          this.liveSdk.setupPlayer({
            ..._config,
          });

          // 自制列表 绑定点击事件
          if(livevod(this.global.year) && livevod(this.global.year)==1){
            // console.log($(".live-content .pc .playbacklist .ri p"));
            $(".live-content .pc .playbacklist .ri p").on("click", (e)=>{
              // console.log(e.target.dataset.index);
              if(this.playback == parseInt(e.target.dataset.index)){
                this.$toast("正在播放中", 600);
              } else {
                this.playback = parseInt(e.target.dataset.index);
                let {videoId: fileId, url, channelSessionId: sessionId} = this.playbackList[this.playback];
                this.liveSdk.switchVod({
                  fileId,
                  sessionId,
                  url
                })
                // this.playbackListShow = false;
                console.log(`切换回放《${this.playbackList[parseInt(e.target.dataset.index)].title}》`);
              }
            })
          }
        });

        // 直播开始/更新
        this.liveSdk.on(PolyvLiveSdk.EVENTS.STREAM_UPDATE, (event, status) => {
          // console.log(status);
          if (status === "live") {
            if (window.confirm("直播已开始，是否观看直播")) {
              window.location.reload();
            } else {
            }
          }
        });
      })(params_c)
    },
    //签名
    getSign() {
      // 获取保利威sign，使用新账号时url增加 +"&type=1"
      let timestamp = new Date().getTime();
      let _url = 
        this.global.year=="2022sz" ? 
        ( "https://www.msup.com.cn/api/api-common/get-polyv-sign?channelId=" +
          this.channelId +
          "&timestamp=" +
          timestamp +
          "&appId=" +
          this.appId +
          "&type=1" ) :
        ( "https://www.msup.com.cn/api/api-common/get-polyv-sign?channelId=" +
          this.channelId +
          "&timestamp=" +
          timestamp +
          "&appId=" +
          this.appId )
      this.$http
        .get(_url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log(res);
          if (res.status === "200") {
            this.sign = res.data.sign;
            this.createConfig(timestamp);
          } else {
            console.log("获取sign失败");
          }
        });
    },
    // 获取 chatroom token（案例学院接口）
    getChatroomToken(params) {
      let baseurl = `https://www.msup.com.cn/api/api-common/get-chat-token`;
      let url = `${baseurl}?channelId=${params.channelId}&userId=${params.userId}&role=${params.role}`;
      return this.$http.jsonp(url)
      .then((res)=>{
        return res.json();
      })
    },
    closeImg() {
      this.showPreview = false;
      this.imgPreview = "";
    },
    openChatroom() {
      $(".chatroom-con-mob").css({"left":"0"});
      $(".chatroom-con-mob .chatroom-mob .polyv-chat-room > .polyv-cr-head").css({"left":"0"});
      $(".chatroom-con-mob .chatroom-mob .polyv-chat-room .polyv-chat-input").css({"left":"0"});
      $(".chatroom-con-mob .chatroom-con-close").css({"right":"3px", "left":"auto"});
      $(".chatroom-con-open").css({"left":"-100%"});
    },
    closeChatroom() {
      // $(".chatroom-con-mob").css({"left":"100vw"});
      $(".chatroom-con-mob").css({"left":"-100vw"});
      $(".chatroom-con-mob .chatroom-mob .polyv-chat-room > .polyv-cr-head").css({"left":"-100vw"});
      $(".chatroom-con-mob .chatroom-mob .polyv-chat-room .polyv-chat-input").css({"left":"-100vw"});
      $(".chatroom-con-mob .chatroom-con-close").css({"right":"auto", "left":"-100vw"});
      $(".chatroom-con-open").css({"left":"0"});
    },
  },
};
</script>

<style lang="scss">
.live-content {
  p {
    margin-bottom: 0;
  }
  .img-preview {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1040;
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    display: table;
    >i {
      width: 47px;
      height: 47px;
      padding: 4px;
      line-height: 32px;
      font-size: 35px;
      font-family: Source Han Sans CN Medium;
      font-weight: 500;
      font-style: normal;
      color: #fff;
      display: inline-block;
      top: 40px;
      right: 60px;
      position: absolute;
      border: 2px solid #FFFFFF;
      border-radius: 100px;
      cursor: pointer;
    }
    .img-con {
      width: 100vw;
      height: 100vh;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      img {
        // 
      }
    }
  }
}
@media (min-width: 1000px) {
  .live-content {
    .mob {
      display: none;
    }
  }
}
@media (max-width: 1001px) {
  .live-content {
    .pc {
      display: none;
    }
  }
}
@media (min-width: 1000px) {
  .live-content {
    padding-top: 40px;
    position: relative;
    .pc {
      margin-left: auto;
      margin-right: auto;
      max-width: 1200px;
      height: 828px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      // overflow-x: hidden;
      .live-con {
        width: 100%;
        max-width: 1200px;
        height: 678px;
        position: relative;
        overflow-x: hidden;
      }
      .playbacklist {
        // width: 300px;
        width: 278px;
        height: 678px;
        display: flex;
        top: 0;
        // z-index: 1100;
        z-index: 119;
        position: absolute;
        transition: right 0.5s ease;
        .le {
          width: 22px;
          min-width: 22px;
          height: 60px;
          margin-top: 309px;
          margin-left: -22px;
          position: relative;
          @keyframes setbordercolor {
            0% {border-color: rgba(255, 255, 255, 0.2);};
            90% {border-color: rgba(255, 255, 255, 1);};
            100% {border-color: rgba(255, 255, 255, 0.2);};
          }
          @keyframes setleft1 {
            0% {margin-left: 30px;};
            90% {margin-left: -5px;};
            100% {margin-left: 0;};
          }
          >div._close:hover {
            border-color: rgba(255, 255, 255, 0.5);
          }
          >div {
            width: 100%;
            height: 60px;
            margin-top: -80px;
            top: 50%;
            z-index: 1000;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.6);
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-right: none;
            border-radius: 8px 0 0 8px;
            animation: setbordercolor 1s ease 1s 1;
            cursor: pointer;
            overflow: hidden;
            div {
              width: 100%;
              height: 100%;
              // padding-left: 6px;
              animation: setleft1 1s ease 1s 1;
              transition: transform 0.5s ease;
              svg {
                transform: scaleY(1.25);
              }
            }
          }
        }
        .ri {
          width: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          border-left: 1px solid rgba(255, 255, 255, 0.2);
          border-image: linear-gradient(rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 260px, transparent 260px, transparent 318px, rgba(255, 255, 255, 0.2) 318px, rgba(255, 255, 255, 0.2) 100%) 1;
          border-image-slice: 0 0 0 100%;
          div {
            width: 100%;
            height: 100%;
            padding: 5px 10px 10px 15px;
            border-top: 1px solid rgba(255, 255, 255, 0.2);
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          }
          p {
            margin-top: 10px;
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 30px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            cursor: pointer;
          }
          p.act {
            font-weight: 400;
            color: #999999;
          }
        }
      }
      .live-player.haschatroom {
        display: flex;
        flex-wrap: nowrap;
        #player {
          width: 100%;
        }
      }
      .live-player {
        width: 1200px;
        height: 678px;
        z-index: 1;
        &.small-view {
          position: fixed;
          right: 60px;
          bottom: 64vh;
          width: 300px;
          height: 200px;
          z-index: 3;
          &.small-top {
            bottom: 340px;
            right: 160px;
          }
        }
      }
      .live-chatroom {
        .polyv-chat-room>.polyv-cr-head, 
        .polyv-chat-room>.polyv-cr-head .polyv-cr-navbar, 
        .polyv-chat-room>.polyv-cr-head .polyv-cr-navbar li {
          padding: 0;
          color: #FFFFFF;
          background-color: #2c2c2c;
          border-bottom: 3px solid #2c2c2c;
        }
        .polyv-chat-room>.polyv-cr-head .polyv-cr-navbar {
          border: 1px solid #2c2c2c;
          border-top: none;
          border-bottom: none;
        }
        .polyv-chat-room>.polyv-cr-head .polyv-cr-navbar li {
          border-bottom: 1px solid rgba(0,0,0,0.5);
        }
        .polyv-chat-room>.polyv-cr-head .polyv-cr-navbar li.polyv-crn-active {
          background-color: #000000;
        }
        .polyv-chat-room>.polyv-cr-body, 
        .polyv-chat-room .polyv-chat-input, 
        .polyv-chat-room .polyv-chat-input textarea,
        .polyv-chat-room .polyv-chat-input .polyv-pc-only-teacher {
          background-color: #2c2c2c;
        }
        .polyv-chat-room .polyv-chat-input {
          border-top: 1px solid rgba(0,0,0,0.5);
        }
        .polyv-chat-room .polyv-msg-content {
          min-height: 0;
          padding: 5px 8px;
          // border-radius: 20px;
          background-color: #000000!important;
        }
        .polyv-chat-room .polyv-chat-list>.polyv-special-user .polyv-msg-text {
          color: #FFFFFF!important;
        }
        .polyv-chat-room .polyv-self-msg .polyv-msg-content>p {
          font-size: 14px;
        }
        .polyv-chat-room .polyv-msg-content img.polyv-img-chat {
          cursor: pointer;
        }
        .polyv-chat-room .polyv-chat-list>.polyv-msg, 
        .polyv-chat-room .polyv-chat-input .polyv-fr span {
          color: #888888;
        }
        .polyv-chat-room .polyv-chat-input .polyv-pc-only-teacher:before {
          margin-right: 3px;
          border-color: #888888;
        }
        .polyv-chat-room li, .polyv-chat-room span {
          font-weight: 500;
        }
        .polyv-chat-room .polyv-user-actor, 
        .polyv-chat-room .polyv-btn-info {
          background-color: #0a70c5;
        }
      }
      .intro-wrapper {
        display: flex;
        justify-content: space-between;
        color: #ffffff;
        .left {
          padding-top: 23px;
          .title {
            font-size: 19px;
            font-weight: 400;
            color: #ffffff;
          }
          .text {
            margin-top: 11px;
            font-size: 14px;
            font-weight: 500;
            color: #999999;
          }
          .line-wrapper {
            padding-top: 16px;
            display: flex;
            .line-item {
              margin-right: 20px;
              border-radius: 12px;
              padding: 4px 12px;
              color: #333;
              font-weight: 500;
              background-color: #fff;
              cursor: pointer;
              &.selected {
                background-color: #feda33;
                color: #fff;
              }
            }
          }
        }
        .right {
          margin-top: 21px;
          margin-bottom: 26px;
          padding: 24px 16px 23px 12px;
          width: 305px;
          height: 103px;
          background: #323232;
          border-radius: 8px;
          display: flex;
          flex-direction: row;
          .logo-wrapper {
            width: 62px;
            height: 62px;
            border: 1px solid #0a70c5;
            border-radius: 50%;
            position: relative;
            box-sizing: border-box;
            .logo {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 56px;
              height: 56px;
              background: #ffffff;
              border-radius: 50%;
            }
          }
          .offcial-account {
            margin-left: 11px;
            margin-right: 9px;
            padding-top: 10px;
            padding-bottom: 10px;
            width: 130px;
            .name {
              font-size: 17px;
              font-weight: 400;
              color: #ffffff;
              text-align: left;
            }
            .offcial-intro {
              // margin-top: 8px;
              font-size: 14px;
              font-weight: 400;
              color: #bcbcbc;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              box-orient: vertical;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              overflow: hidden;
            }
          }
          .follow-btn {
            padding-top: 4px;
            position: relative;
            button {
              outline: none;
              border: none;
              width: 65px;
              height: 26px;
              background: #0a70c5;
              color: #fff;
              border-radius: 4px;
              font-size: 14px;
              font-weight: 400;
            }
            .qrcode-wrapper {
              border-radius: 6px;
              position: absolute;
              top: 36px;
              right: 0;
              background: #ffffff;
              text-align: center;
              width: 130px;
              padding: 18px 0;
              &::after {
                position: absolute;
                top: -6px;
                right: 20%;
                content: "";
                width: 12px;
                height: 12px;
                display: block;
                background: #fff;
                transform-origin: center;
                transform: rotate(135deg);
              }
              .wechat-wrapper {
                color: #adadad;
                font-size: 11px;
                .wechat-logo {
                  width: 16px;
                  height: 14px;
                }
              }
              .code {
                margin-top: 4px;
                margin-bottom: 6px;
                width: 78px;
                height: 78px;
              }
              .text {
                display: block;
                color: #6a6a6a;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1001px) {
  .live-content {
    .mob {
      position: relative;
      padding-top: 264px;
      // padding-top: 210px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #f7f7f7;
      .back-wrapper {
        padding: 4px 4px 4px 2px;
        position: fixed;
        top: 20px;
        left: 2vw;
        background: #333;
        opacity: 0.3;
        z-index: 10;
        border-radius: 50%;
      }
      #playerMob {
        width: 100vw;
        position: fixed;
        // top: 0;
        top: 54px;
        left: 0;
        right: 0;
        z-index: 8;
      }
      .title-wrapper {
        width: 100%;
        padding-left: 6.4vw;
        padding-right: 12vw;
        padding-bottom: 28px;
        .title {
          padding-top: 12.5px;
          padding-bottom: 12px;
          color: #222222;
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
        }
        .text {
          font-size: 12px;
          color: #666666;
        }
        .line-wrapper {
          padding-top: 12px;
          display: flex;
          .line-item {
            border-radius: 12px;
            padding: 4px 12px;
            font-size: 16px;
            margin-right: 16px;
            color: #333;
            font-weight: 500;
            background-color: #fff;
            cursor: pointer;
            &.selected {
              background-color: #feda33;
              color: #fff;
            }
          }
        }
      }
      .offcial-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11.5px 6vw 13.5px 5.8vw;
        background: #ffffff;
        .logo {
          border: 1px solid #a6a6a6;
          width: 10.6vw;
          height: 10.6vw;
          border-radius: 50%;
        }
        .offcial-account {
          margin-left: 3.5vw;
          margin-right: auto;
          .name {
            color: #222222;
            font-size: 14px;
            text-align: left;
          }
          .offcial-intro {
            // margin-top: 8px;
            color: #bcbcbc;
            font-size: 14px;
            width: 50vw;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            box-orient: vertical;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            overflow: hidden;
          }
        }
        .follow-btn {
          button {
            width: 16vw;
            height: 8vw;
            border: none;
            outline: none;
            background: #0a70c5;
            color: #fff;
            font-size: 14px;
            box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.1),
              0px 1px 0px 0px rgba(255, 255, 255, 0.15);
            border-radius: 4px;
          }
        }
      }
      .chatroom-con-open {
        left: 0;
        bottom: 25px;
        z-index: 9;
        position: fixed;
        display: flex;
        flex-wrap: nowrap;
        // background-color: rgba(0, 0, 0, 0.6);
        background-color: rgba(10, 112, 197, 0.6); // #0a70c5;
        border: 1px solid rgba(255, 255, 255, 0.8);
        border-left: none;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        transition: all ease 0.5s;
        &:hover {
          background-color: rgba(24, 137, 203, 0.8);
        }
        p {
          font-weight: 500;
          color: #FFFFFF;
        }
        p:nth-of-type(1){
          width: min-content;
          padding: 2px 2px 2px 3px;
        }
        p:nth-of-type(2){
          width: fit-content;
          line-height: 1;
          // transform: translate(-50%, -50%);
          transform: rotate(180deg);
          transform-origin: center;
          display: table;
          span {
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
      .chatroom-con-mob {
        width: 100vw;
        height: 100vh;
        padding: 85px 25px 35px 25px;
        position: fixed;
        top: 0;
        // left: 0;
        left: -100vw;
        z-index: 11;
        background-color: rgba(0, 0, 0, 0.5);
        overflow: hidden;
        transition: all ease 0.5s;
        // display: table;
        .chatroom-con-close {
          width: 25px;
          height: 25px;
          top: 56px;
          // left: auto;
          // right: 3px;
          left: -100vw;
          right: auto;
          position: fixed;
          display: inline-block;
          border-radius: 50px;
          border: 1px solid rgba(255, 255, 255, 0.6);
          transition: all ease 0.5s;
          text-align: center;
          i {
            width: 20px;
            height: 20px;
            padding: 2px 0;
            margin: 0 auto;
            line-height: 18px;
            font-size: 18px;
            font-family: Source Han Sans CN Medium;
            font-weight: 500;
            font-style: normal;
            color: rgba(255, 255, 255, 0.7);
            display: inline-block;
          }
        }
        .chatroom-mob {
          width: 100%;
          height: 100%;
          // display: table-cell;
          // background-color: #FFFFFF;
          background-color: #e9ebf5;
          overflow: auto;
          .polyv-chat-room>.polyv-cr-head,
          .polyv-chat-room .polyv-chat-input {
            position: fixed;
            padding: 0 25px;
            left: -100vw;
            z-index: 10;
            background: #FFFFFF;
            background-clip: content-box;
            transition: all ease 0.5s;
          }
          .polyv-chat-room>.polyv-cr-head {
            top: 85px;
          }
          .polyv-chat-room .polyv-chat-input {
            bottom: 35px;
          }
          .polyv-emotion-wrap {
            left: 25px;
            margin-bottom: 8px;
          }
          .mobile-wrap .polyv-chat-input-more {
            top: -101px;
            padding: 0 25px;
            z-index: 10;
            background: #FFFFFF;
            background-clip: content-box;
            transition: all ease 0.5s;
          }
        }
      }
      .img-preview {
        >i {
          width: 28px;
          height: 28px;
          padding: 2px;
          line-height: 20px;
          font-size: 20px;
          top: 15px;
          right: 15px;
        }
      }
    }
    .img-preview>i {
      width: 30px;
      height: 30px;
      line-height: 20px;
      font-size: 20px;
      padding: 3px;
      top: 10px;
      right: 23px;
    }
  }
}
</style>
